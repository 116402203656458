import * as React from "react";
import { Link, useParams } from "react-router-dom";
import cn from "classnames";

import { ProductSuggestionsIndexRouteUrl } from "./ProductSuggestionsIndexPage";
import {
  useAdminAcceptProductSuggestionMutation,
  useAdminBrandResolverQuery,
  useAdminCategoryNameQuery,
  useAdminProductToVerifyQuery,
} from "../../graphql/graphql";
import {
  Accordion,
  Badge,
  Button,
  ButtonGroup,
  Group,
  List,
  Text,
  Title,
} from "@mantine/core";
import { TimeAgo } from "@zozia/ui";

const translateSuggestionReason = (reason: string) => {
  switch (reason) {
    case "changed":
      return "Zmiana";
    case "added":
      return "Dodanie";
    default:
      return reason;
  }
};

const translateSuggestionStatus = (status: string) => {
  status = status.toLowerCase();

  switch (status) {
    case "pending":
      return "Oczekujące";
    case "approved":
      return "Zaakceptowane";
    case "rejected":
      return "Odrzucone";
    default:
      return status;
  }
};

const translateSuggestionProperty = (property: string) => {
  const isImage = property.startsWith("images");

  if (isImage) {
    return `Zdjęcie nr ${property.split("_")[1]}`;
  }

  switch (property) {
    case "name":
      return "Nazwa";
    case "description":
      return "Opis";
    case "price":
      return "Cena";
    case "quantity":
      return "Ilość";
    case "categoryId":
      return "Kategoria";
    case "brandId":
      return "Marka";

    default:
      return property;
  }
};

const ProductSuggestionsDetailsForm = () => {
  const { productSuggestionId } = useParams();
  const { data, isLoading } = useAdminProductToVerifyQuery({
    productSuggestionId,
  });

  const { mutate } = useAdminAcceptProductSuggestionMutation();

  const [acceptedSuggestions, setAcceptedSuggestions] = React.useState([]);
  const [rejectedSuggestions, setRejectedSuggestions] = React.useState([]);

  React.useEffect(() => {
    if (data?.adminProductToVerify) {
      setAcceptedSuggestions(
        data.adminProductToVerify.suggestions.filter(
          (suggestion) => suggestion.status.toLowerCase() === "approved",
        ),
      );

      setRejectedSuggestions(
        data.adminProductToVerify.suggestions.filter(
          (suggestion) => suggestion.status.toLowerCase() === "rejected",
        ),
      );
    }
  }, [data]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const shouldButtonBeDisabled =
    data.adminProductToVerify &&
    ["APPROVED", "REJECTED"].includes(data.adminProductToVerify.status);

  return (
    <div>
      <Title>
        Sugestie zmian w produkcie {data.adminProductToVerify.product.name}{" "}
        {data.adminProductToVerify.status === "PENDING" && (
          <Badge color="blue">Oczekujące</Badge>
        )}
        {data.adminProductToVerify.status === "APPROVED" && (
          <Badge color="green">Zaakceptowane</Badge>
        )}
        {data.adminProductToVerify.status === "REJECTED" && (
          <Badge color="red">Odrzucone</Badge>
        )}
      </Title>
      <List listStyleType="none">
        <List.Item>
          Osoba zgłaszająca zmianę: {data.adminProductToVerify.requester.email}
        </List.Item>
        <List.Item>
          Data zgłoszenia:{" "}
          <TimeAgo value={data.adminProductToVerify.createdAt} />
        </List.Item>
        <List.Item>
          Data ostatniej aktualizacji:{" "}
          <TimeAgo value={data.adminProductToVerify.updatedAt} />
        </List.Item>
        <List.Item>
          ID produktu: {data.adminProductToVerify.product.id}
        </List.Item>
        <List.Item>
          EAN produktu: {data.adminProductToVerify.product.ean}
        </List.Item>
        <List.Item>
          Link do produktu:{" "}
          <Link
            to={`/collections/products/${data.adminProductToVerify.product.id}`}
            target="_blank"
          >
            {data.adminProductToVerify.product.name}
          </Link>
        </List.Item>
      </List>

      <Text mt="lg">Zmiany w produkcie:</Text>
      <Accordion>
        {data.adminProductToVerify.suggestions.map((suggestion) => {
          const property = Object.keys(suggestion.data)[0];
          const suggestionData = suggestion.data[property];

          const propertyType = (() => {
            if (property.startsWith("images")) {
              return "image";
            }

            switch (property) {
              case "name":
              case "categoryId":
              case "brandId":
                return "text";
              case "description":
                return "textarea";
              case "price":
                return "number";
              default:
                return property;
            }
          })();

          const suggestionStatus = (() => {
            if (acceptedSuggestions.some((s) => s.id === suggestion.id)) {
              return "accepted";
            }

            if (rejectedSuggestions.some((s) => s.id === suggestion.id)) {
              return "rejected";
            }

            return suggestion.status;
          })();
          console.log({ status: data.adminProductToVerify?.status });

          return (
            <Accordion.Item key={suggestion.id} value={suggestion.id}>
              <Accordion.Control
                classNames={{
                  control: cn({
                    "bg-green-100": suggestionStatus === "accepted",
                    "bg-red-100": suggestionStatus === "rejected",
                  }),
                }}
              >
                <Text>
                  {translateSuggestionReason(suggestion.reason)} wartości{" "}
                  {translateSuggestionProperty(property)}{" "}
                  {["APPROVED", "REJECTED"].includes(
                    data.adminProductToVerify?.status,
                  ) ? null : (
                    <Badge color="blue">
                      {translateSuggestionStatus(suggestion.status)}
                    </Badge>
                  )}{" "}
                  {suggestionStatus === "accepted" && (
                    <Badge color="green">Zaakceptowane</Badge>
                  )}
                  {suggestionStatus === "rejected" && (
                    <Badge color="red">Odrzucone</Badge>
                  )}
                </Text>
              </Accordion.Control>
              <Accordion.Panel>
                {(() => {
                  switch (propertyType) {
                    case "textarea": {
                      return (
                        <pre>{JSON.stringify(suggestionData, null, 2)}</pre>
                      );
                    }
                    case "image": {
                      return (
                        <Group>
                          <Text className="text-center">Dodane zdjęcie</Text>
                          <img
                            src={suggestionData.url}
                            alt={suggestionData.url}
                            className="w-80"
                          />
                        </Group>
                      );
                    }
                    case "text": {
                      return (
                        <List listStyleType="none">
                          {suggestion.reason !== "added" ? (
                            <List.Item>
                              <Text>
                                <b>Stara wartość:</b> {suggestionData.__old}
                                {property === "brandId" ? <SuggestionBrandResolver id={suggestionData.__old} /> : null}
                                {property === "categoryId" ? <SuggestionCategoryResolver id={suggestionData.__old} /> : null}
                              </Text>
                            </List.Item>
                          ) : null}
                          <List.Item>
                            <Text>
                              <b>Nowa wartość:</b> {suggestionData.__new}
                              {property === "brandId" ? <SuggestionBrandResolver id={suggestionData.__new} /> : null}
                              {property === "categoryId" ? <SuggestionCategoryResolver id={suggestionData.__new} /> : null}
                            </Text>
                          </List.Item>
                        </List>
                      );
                    }
                  }
                })()}
                {shouldButtonBeDisabled ? null : (
                  <ButtonGroup>
                    <Button
                      color="green"
                      onClick={() => {
                        setRejectedSuggestions(
                          rejectedSuggestions.filter(
                            (s) => s.id !== suggestion.id,
                          ),
                        );
                        setAcceptedSuggestions([
                          ...acceptedSuggestions,
                          suggestion,
                        ]);
                      }}
                    >
                      Zaakceptuj
                    </Button>
                    <Button
                      color="red"
                      onClick={() => {
                        setAcceptedSuggestions(
                          acceptedSuggestions.filter(
                            (s) => s.id !== suggestion.id,
                          ),
                        );
                        setRejectedSuggestions([
                          ...rejectedSuggestions,
                          suggestion,
                        ]);
                      }}
                    >
                      Odrzuć
                    </Button>
                  </ButtonGroup>
                )}
              </Accordion.Panel>
            </Accordion.Item>
          );
        })}
      </Accordion>

      {acceptedSuggestions.length + rejectedSuggestions.length ===
        data.adminProductToVerify.suggestions.length &&
        !shouldButtonBeDisabled && (
          <Button
            color="blue"
            onClick={() => {
              mutate({
                input: {
                  acceptedSuggestions: acceptedSuggestions.map((s) => s.id),
                  rejectedSuggestions: rejectedSuggestions.map((s) => s.id),
                  productRequestUpdateId: data.adminProductToVerify.id,
                },
              });
            }}
          >
            Zapisz zmiany do produktu
          </Button>
        )}
    </div>
  );
};

export const ProductSuggestionsDetailsIndexPage = () => {
  return <ProductSuggestionsDetailsForm />;
};

export const ProductSuggestionsDetailsIndexRouteUrl = (id: string) =>
  `${ProductSuggestionsIndexRouteUrl}/${id}`;


export const SuggestionBrandResolver = ({ id }) => {
  const { data } = useAdminBrandResolverQuery({
    brandId: id,
  });

  return <pre>{JSON.stringify(data)}</pre>
}

const SuggestionCategoryResolver = ({ id }) => {
  const { data } = useAdminCategoryNameQuery({
    categoryId: id,
  });

  return <pre>{JSON.stringify(data)}</pre>
}